<template>
	<div>
		<van-divider
			:style="{
				color: '#1989fa',
				borderColor: '#1989fa',
				padding: '0 16px',
			}"
		>
			热门课程
		</van-divider>
		<van-card
			@click="handleCourseInfo(item.id)"
			v-for="(item, i) in courseArr"
			:key="i"
			:title="item.courseName"
		>
			<template #thumb>
				<van-image
					width="35vw"
					height="100%"
					fit="cover"
					:src="item.coursePicture"
				/>
			</template>
			<template #desc>
				<p>{{ item.siteName }}</p>
				<p>{{ item.siteAddress }}</p>
			</template>
			<template #tags v-if="courseLabel.length>0">
				<van-tag
					v-for="(val, index) in item.courseLabel"
					:key="index"
					plain
					type="primary"
					>{{ courseLabel[val-1].dictValue }}</van-tag
				>
				<van-tag plain type="primary">{{
					item.courseLimitGrade
				}}</van-tag>
			</template>
		</van-card>
	</div>
</template>

<script>
export default {
	props: ['courseArr'],
	data() {
		return {
			courseLabel: [],
		}
	},
	methods: {
		handleCourseInfo(courseId) {
			this.$router.push('/mobile/courseInfo?courseId=' + courseId)
		},
	},
	mounted() {
        this.courseLabel=this.$store.state.courseLabelDict
	},
}
</script>

<style lang="less" scoped>
.van-card__thumb {
	width: 35vw;
	min-height: 20vw;
}
.van-card__content,
.van-card__title {
	line-height: 4.8vw;
	max-height: none;
	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.van-tag {
		margin-right: 4px;
	}
}
</style>
