<template>
  <div>
    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      热门场景
    </van-divider>
    <div @click="handleScene(item.id,item.siteCourse)" v-for="(item, i) in sceneArr" :key="i" class="scene-list">
      <van-image width="100%" fit="cover" :src="item.sitePicture" />
      <p>{{ item.siteName }}</p>
      <p>已预约{{ item.siteHeat }}人次</p>
    </div>
  </div>
</template>

<script>
import {sessionSet} from "@/utils/local" 
export default {
  props: ["sceneArr"],
  methods:{
    handleScene(id,siteCourse){
        // sessionSet("siteCourse",siteCourse)
         this.$store.commit("updateSiteCourse",siteCourse);
       this.$router.push({path:"/mobile/scenceInfo",query:{id:id}});
    },
  },
};
</script>

<style lang="less" scoped>
.scene-list {
  border: 0.1px solid #d9ecff;
  // width: calc(100% - 0.2px);
  height: 60vw;
  border-radius: 10px;
  margin: 3%;
  padding: 2%;
  background-color: #fff;
  .van-image {
    height: calc(100% - 40px);
  }
  p {
    font-size: 0.8em;
    line-height: 20px;
    letter-spacing: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>