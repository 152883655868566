<template>
  <div>
    <van-notice-bar left-icon="volume-o" :scrollable="false">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="2500"
        :show-indicators="false"
      >
        <van-swipe-item
          @click="handleNotice(item)"
          v-for="(item, i) in noticeArr"
          :key="i"
          >{{ item.title }}</van-swipe-item
        >
      </van-swipe>
    </van-notice-bar>
    <van-action-sheet v-model="show" :title="noticeInfo.title">
      <div class="content">
        <p class="time">发布时间：{{ noticeInfo.releaseTime }}</p>
        <div class="richText ql-editor" v-html="noticeInfo.content"></div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  props: ["noticeArr"],
  data() {
    return {
      show: false,
      noticeInfo: {},
    };
  },
  methods: {
    handleNotice(item) {
      this.show = true;
      this.noticeInfo = item;
    },
  },
};
</script>

<style lang="less" scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.content {
  padding: 16px;
  font-size: 0.9em;
  line-height: 1.3em;
  .richText,
  .ql-editor {
    /deep/img {
      max-width: 100%;
    }
  }
}
</style>