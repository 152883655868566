<template>
	<div>
		<Swipe :slideshow="slideshow" />
		<Notice :noticeArr="noticeArr" />
		<Course v-if="courseArr.length > 0" :courseArr="courseArr" />
		<Scene v-if="sceneArr.length > 0" :sceneArr="sceneArr" />
	</div>
</template>

<script>
import Swipe from './components/swipe'
import Notice from './components/notice'
import Course from './components/course'
import Scene from './components/scene'
import { indexSlide_api } from '@/api/index.js'
import { getNoticeList_api } from '@/api/notice.js'
import { getCourseList_api } from '@/api/course.js'
import { getSceneList_api } from '@/api/scene'
export default {
	components: {
		Swipe,
		Notice,
		Course,
		Scene,
	},
	data() {
		return {
			slideshow: [],
			noticeArr: [],
			courseArr: [],
			sceneArr: [],
		}
	},
	methods: {
		// 获取轮播数据（顶部轮播）
		async getSlideImg() {
			let { data, code } = await indexSlide_api({
				tenantId: this.$tenantId,
			})
			if (code == 200) this.slideshow = data
		},
		// 获取通知公告数据
		async getNoticeList() {
			let { data, code, msg } = await getNoticeList_api({
				tenantId: this.$tenantId,
				size: 8,
				sort: 'create_time',
				sortType: 'desc',
				page: 1,
			})
			if (code == 200) {
				// console.log(data)
				this.noticeArr = data.list
			}
		},
		// 获取热门课程列表
		async getCourseList() {
			let { data, code } = await getCourseList_api({
				tenantId: this.$tenantId,
				size: 10,
				sort: 'course_heat',
				sortType: 'desc',
				page: 1,
			})
			if (code !== 200) return null
			// 数据处理
			data.records.forEach((item) => {
				if (item.courseLimitGrade) item.courseLimitGrade += '年级'
				if (item.courseLabel && typeof item.courseLabel === 'string')
					item.courseLabel = item.courseLabel.split(',')
			})
			this.courseArr = data.records
		},
		// 获取场景资源数据
		async getSceneList() {
			let { data, code } = await getSceneList_api({
				tenantId: this.$tenantId,
				size: 5,
				sort: 'site_heat',
				sortType: 'desc',
				current: 1,
			})
			if (code != 200) return null
			this.sceneArr = data.records
		},
	},
	created() {
		this.getSlideImg()
		this.getNoticeList()
		this.getCourseList()
		this.getSceneList()
	},
}
</script>

<style lang="less" scoped></style>
