<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, i) in slideshow" :key="i">
        <el-image
          :src="item"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: ["slideshow"],
};
</script>

<style lang="less" scoped>
/deep/.el-image{
  height:50vw;
  width:100vw;
}
</style>